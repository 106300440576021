<template>
  <modal v-if="showModal" @close="hide" class="modal-user">
    <template slot="header">
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="hide"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>
    <template slot="body">
      <h4 class="modal-title">{{ title }}</h4>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            name="displayName"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field
              class="md-form-group"
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
            >
              <md-icon>assignment_ind</md-icon>
              <label>Display Name</label>
              <md-input
                v-model="user.displayName"
                type="text"
                required
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <ValidationProvider
            name="firstName"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field
              class="md-form-group"
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
            >
              <md-icon>face</md-icon>
              <label>First Name</label>
              <md-input
                v-model="user.firstName"
                type="text"
                required
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <ValidationProvider
            name="lastName"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field
              class="md-form-group"
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
            >
              <md-icon>face</md-icon>
              <label>Last Name</label>
              <md-input v-model="user.lastName" type="text" required></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed }"
          >
            <md-field
              class="md-form-group"
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
            >
              <md-icon>email</md-icon>
              <label>Email</label>
              <md-input v-model="user.email" type="email" required></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <ValidationProvider
            name="password"
            rules="required|min:5|confirmed:confirmation"
            v-slot="{ passed, failed }"
            v-if="operationType === 'create'"
          >
            <md-field
              class="md-form-group"
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
            >
              <md-icon>vpn_key</md-icon>
              <label>Password (min. 5 characters)</label>
              <md-input
                v-model="user.password"
                type="password"
                required
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <ValidationProvider
            vid="confirmation"
            rules="required"
            v-slot="{ passed, failed }"
            v-if="operationType === 'create'"
          >
            <md-field
              class="md-form-group"
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
            >
              <md-icon>vpn_key</md-icon>
              <label>Confirm Password</label>
              <md-input
                v-model="user.confirmPassword"
                type="password"
                required
              ></md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>

          <md-field class="md-form-group">
            <md-icon>person_add</md-icon>
            <label for="rolesSelect">Roles</label>

            <md-select
              v-model="user.rolesNames"
              name="rolesSelect"
              id="rolesSelect"
              multiple
            >
              <md-option
                v-for="role in existingRoles"
                :key="role"
                :value="role"
                :label="role"
                >{{ role }}</md-option
              >
            </md-select>
          </md-field>

          <div class="form-required">
            <p>* Required fields</p>
          </div>

          <md-button class="md-success md-simple" type="submit">{{
            confirmButtonLabel
          }}</md-button>
          <md-button class="md-danger md-simple" @click="hide"
            >Cancel</md-button
          >
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import { Modal } from '@/components/Template';
import { SlideYDownTransition } from 'vue2-transitions';
import { extend } from 'vee-validate';
import {
  required, email, confirmed, min,
} from 'vee-validate/dist/rules';

extend('email', email);
extend('required', required);
extend('confirmed', confirmed);
extend('min', min);

export default {
  components: {
    Modal,
    SlideYDownTransition,
  },
  props: {
    user: Object,
    existingRoles: Array,
    showModal: Boolean,
    operationType: String,
    title: String,
    confirmButtonLabel: String,
    valueLabel: String,
  },
  data() {
    return {
      rolesSelected: [],
    };
  },
  created() {},
  methods: {
    onSubmit() {
      this.$emit('confirm', { ...this.user });
    },
    hide() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.left-margin-12 {
  margin-left: 12px;
}

h4 {
  margin-top: 0px;
}

.modal-user {
  z-index: 11;
}

.form-required {
  text-align: left;
  font-size: 14px;
}
</style>
<style>
.md-input.md-select-value {
  margin-left: 12px;
}
</style>
