var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModal)?_c('modal',{staticClass:"modal-user",on:{"close":_vm.hide}},[_c('template',{slot:"header"},[_c('md-button',{staticClass:"md-simple md-just-icon md-round modal-default-button",on:{"click":_vm.hide}},[_c('md-icon',[_vm._v("clear")])],1)],1),_c('template',{slot:"body"},[_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"displayName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{staticClass:"md-form-group",class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-icon',[_vm._v("assignment_ind")]),_c('label',[_vm._v("Display Name")]),_c('md-input',{attrs:{"type":"text","required":""},model:{value:(_vm.user.displayName),callback:function ($$v) {_vm.$set(_vm.user, "displayName", $$v)},expression:"user.displayName"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{staticClass:"md-form-group",class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-icon',[_vm._v("face")]),_c('label',[_vm._v("First Name")]),_c('md-input',{attrs:{"type":"text","required":""},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{staticClass:"md-form-group",class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-icon',[_vm._v("face")]),_c('label',[_vm._v("Last Name")]),_c('md-input',{attrs:{"type":"text","required":""},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{staticClass:"md-form-group",class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-icon',[_vm._v("email")]),_c('label',[_vm._v("Email")]),_c('md-input',{attrs:{"type":"email","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)}),(_vm.operationType === 'create')?_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{staticClass:"md-form-group",class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-icon',[_vm._v("vpn_key")]),_c('label',[_vm._v("Password (min. 5 characters)")]),_c('md-input',{attrs:{"type":"password","required":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)}):_vm._e(),(_vm.operationType === 'create')?_c('ValidationProvider',{attrs:{"vid":"confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{staticClass:"md-form-group",class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-icon',[_vm._v("vpn_key")]),_c('label',[_vm._v("Confirm Password")]),_c('md-input',{attrs:{"type":"password","required":""},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)}):_vm._e(),_c('md-field',{staticClass:"md-form-group"},[_c('md-icon',[_vm._v("person_add")]),_c('label',{attrs:{"for":"rolesSelect"}},[_vm._v("Roles")]),_c('md-select',{attrs:{"name":"rolesSelect","id":"rolesSelect","multiple":""},model:{value:(_vm.user.rolesNames),callback:function ($$v) {_vm.$set(_vm.user, "rolesNames", $$v)},expression:"user.rolesNames"}},_vm._l((_vm.existingRoles),function(role){return _c('md-option',{key:role,attrs:{"value":role,"label":role}},[_vm._v(_vm._s(role))])}),1)],1),_c('div',{staticClass:"form-required"},[_c('p',[_vm._v("* Required fields")])]),_c('md-button',{staticClass:"md-success md-simple",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.confirmButtonLabel))]),_c('md-button',{staticClass:"md-danger md-simple",on:{"click":_vm.hide}},[_vm._v("Cancel")])],1)]}}],null,false,2969470928)})],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }