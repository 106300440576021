<template>
  <div>
    <modal-user
      v-if="showModal"
      :title="modalTitle"
      :user="selectedUser"
      :existingRoles="existingRoles"
      :confirmButtonLabel="operationType"
      :operationType="operationType"
      :showModal="showModal"
      @close="showModal = false"
      @confirm="confirm"
    ></modal-user>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-cyan">
            <div class="card-icon">
              <md-icon>people_outline</md-icon>
            </div>
            <h4 class="title">Users</h4>
          </md-card-header>
          <md-card-content>
            <md-card-actions md-alignment="right">
              <md-button
                class="md-info md-sm md-round"
                @click.native="createUserButtonClicked"
              >
                <md-icon>person_add</md-icon>Create User
              </md-button>
            </md-card-actions>
            <md-table v-model="tableData">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Display Name">{{
                  item.displayName
                }}</md-table-cell>
                <md-table-cell md-label="First Name">{{
                  item.firstName
                }}</md-table-cell>
                <md-table-cell md-label="Last Name">{{
                  item.lastName
                }}</md-table-cell>
                <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
                <md-table-cell md-label="Roles">{{
                  rolesAsString(item.rights)
                }}</md-table-cell>
                <md-table-cell md-label="Actions" class="text-right">
                  <md-button
                    class="md-just-icon md-info edit-button"
                    @click.native="editUser(item)"
                  >
                    <md-icon>edit</md-icon>
                    <md-tooltip md-delay="300">Edit</md-tooltip>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger"
                    @click.native="handleDelete(item)"
                  >
                    <md-icon>delete</md-icon>
                    <md-tooltip md-delay="300">Delete</md-tooltip>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/api';
import ModalUser from '@/components/Avalia/Modals/ModalUser.vue';
import Swal from 'sweetalert2';

const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'md-button md-success btn-fill',
    cancelButton: 'md-button md-danger btn-fill',
  },
  buttonsStyling: false,
});

export default {
  components: {
    ModalUser,
  },
  data() {
    return {
      selectedUser: undefined,
      showModal: false,
      operationType: 'create',
      modalTitle: '',
    };
  },
  created() {
    this.fetchUsers();
    this.fetchExistingRoles();
  },
  computed: {
    tableData() {
      return this.currentUsers.map((user) => ({
        ...user,
        rolesNames: user.rights ? user.rights : [],
      }));
    },
    currentUsers() {
      return this.$store.getters['users/GET_USERS'];
    },
    existingRoles() {
      return this.$store.getters['users/GET_USERS_ROLES'];
    },
  },
  methods: {
    fetchExistingRoles() {
      this.$store.dispatch('users/fetchUserRoles', this);
    },
    fetchUsers() {
      this.$store.dispatch('users/fetchUsers', this);
    },
    createUserButtonClicked(event) {
      this.selectedUser = {
        displayName: '',
        email: '',
        firstName: '',
        id: '',
        lastName: '',
        password: '',
        rolesNames: [],
      };
      this.modalTitle = 'Create User';
      this.operationType = 'create';
      this.showModal = true;
    },
    editUser(user) {
      this.selectedUser = { ...user };
      this.modalTitle = 'Edit User';
      this.operationType = 'update';
      this.showModal = true;
    },
    handleDelete(user) {
      swalWithCustomButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert the deletion of this User!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteUser(user);
          }
        });
    },
    deleteUser(user) {
      const payload = {
        userId: user.id,
        vm: this,
      };
      this.$store.dispatch('users/deleteUser', payload);
    },
    rolesAsString(rights) {
      return rights ? rights.join(', ') : '';
    },
    confirm(user) {
      switch (this.operationType) {
        case 'create':
          this.confirmCreateUser(user);
          break;
        case 'update':
          this.confirmUpdateUser(user);
          break;
        default:
      }
    },
    confirmCreateUser(user) {
      const message = 'The User has been created';
      return api.idm
        .post('/api/internal/users', [
          {
            displayName: user.displayName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
            rolesNames: user.rolesNames,
          },
        ])
        .then((response) => {
          this.$notify({
            message,
            icon: 'add_success',
            type: 'success',
            horizontalAlign: 'right',
            verticalAlign: 'top',
          });
          this.fetchUsers();
          this.showModal = false;
        })
        .catch((error) => {
          this.$notify({
            message: 'User creation failed',
            icon: 'add_alert',
            type: 'warning',
            timeout: 3500,
            horizontalAlign: 'right',
            verticalAlign: 'top',
          });
        });
    },
    confirmUpdateUser(user) {
      const message = 'The User has been updated';
      const userUpdate = {};
      if (this.selectedUser.email !== user.email) {
        userUpdate.email = user.mail;
      }
      userUpdate.displayName = user.displayName;
      userUpdate.firstName = user.firstName;
      userUpdate.lastName = user.lastName;
      userUpdate.rolesNames = user.rolesNames;

      return api.idm
        .patch(`/api/internal/users/${user.id}`, userUpdate)
        .then((response) => {
          this.$notify({
            message,
            icon: 'add_success',
            type: 'success',
            horizontalAlign: 'right',
            verticalAlign: 'top',
          });
          this.fetchUsers();
          this.showModal = false;
        })
        .catch((error) => {
          this.$notify({
            message: 'User updating failed',
            icon: 'add_alert',
            type: 'warning',
            timeout: 3500,
            horizontalAlign: 'right',
            verticalAlign: 'top',
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.text-right >>> .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table >>> .md-table-head:last-child {
  text-align: right;
}

.md-card-actions {
  border: none;
}

.edit-button {
  margin-right: 1rem;
}
</style>
